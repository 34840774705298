import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ShippingPage = () => (
  <Layout>
    <SEO
      title="Privacy Policy"
      keywords={[
        `australian`,
        `exclusive`,
        `jewellery`,
        `engagement`,
        `wedding`,
      ]}
    />
    <div className="privbuffer">
      <div className="privwrap">
        <div className="termstitle">SHIPPING AND PRODUCTION</div>
        <div className="termscontent">
          All Dianne Rowe Private Collection pieces are hand made to order. This
          means that each piece is unique and made especially for you. If you
          need a piece before a specific date, please contact us before ordering
          to ensure this will be possible and avoid any disappointment. Every
          effort will be made to have your piece to you as quickly as possible.
          <br />
          <br />
          Australian domestic orders are sent express via Australia Post. You
          will be contacted on dispatch of your order with a tracking number
          which you can check at www.auspost.com.au.
          <br />
          <br />
          Dianne Rowe Private Collection ships worldwide. International standard
          shipping is sent via Australia Post. These packages are registered and
          trackable, you will be contacted on dispatch of your order with a
          tracking number which you can check at www.auspost.com.au.
          International standard parcels can take anywhere between 3 and 10
          working days to arrive.
          <br />
          <br />
          International express shipping is via Australia Post. You will be
          contacted on dispatch of your order with a tracking number and details
          of how to track.
        </div>
        <div className="termstitle">Duties and taxes</div>
        <div className="termscontent">
          Occasionally, dependant on the recipient country and the relevant
          import laws, duties and taxes may be payable. As the recipient, you
          are liable for all import duties, customs and local sales taxes levied
          by the country you are shipping to; payment of these is necessary to
          release your order from customs on arrival.
        </div>
        <div className="termstitle">WARRANTY AND RETURNS</div>
        <div className="termscontent">
          Goods sold are non-refundable. All items are made to order therefore
          exchanges or returns are not offered for change of mind.
          <br />
          <br />
          Dianne Rowe Private Collection covers the cost of reasonable repairs
          under normal wear for the first six months after purchase. If a piece
          shows signs of improper care as deemed by Dianne Rowe staff, or is
          damaged in a way resulting from something other than normal wear, we
          will repair the piece at cost. An estimate of the repair can be given
          prior to the repair. Customers are responsible for return shipping
          costs.
          <br />
          <br />
          After 6 months, we will repair pieces at cost. Any necessary repair of
          bespoke pieces will be assessed on a case by case basis.
          <br />
          <br />
          Please note that if any other jeweller undertakes work on one of our
          pieces, including resizing or repairs, this warranty will be voided.
          We strongly recommend returning to us for repairs and resizes, we do
          not take any responsibility for repairs or resizes undertaken by other
          jewellers. Resizing is at cost and is quoted on a case by case basis.
        </div>
        <div className="termstitle">Repair and Service</div>
        <div className="termscontent">
          If your piece needs service or repair, you can return it to us at the
          below address:
          <br />
          <br />
          PO Box 289, Midland DC, WA, 6939
          <br />
          <br />
          Please contact us prior to returning your piece, and ensure that all
          of your details, including your order number and return address are
          included. If you are returning a piece for resize, please ensure the
          new size is included and has been accurately measured.
          <br />
          <br />
          We recommend using a courier service with full insurance to return
          your piece. Please ensure the shipment requires a signature on
          delivery and the piece is adequately packed and padded. We will not
          accept any liability if the piece is damaged in transit due to poor
          packaging.
          <br />
          <br />
          We will let you know once we have received your piece, and will give
          you an indication of any cost involved, and an expected timeframe. For
          queries regarding repairs and resizes, please contact
          info@rowecollection.com.au
          <br />
          <br />
        </div>
      </div>
    </div>
  </Layout>
)

export default ShippingPage
